import React, { useEffect, useState } from 'react';

const Skeleton = ({
  width,
  height = '1rem',
  maxWidth = 100,
  minWidth = 75,
  mode = 'light',
  className = '',
}) => {
  const initialWidth = width || `${Math.floor(Math.random() * (maxWidth - minWidth) + minWidth)}%`;
  const [computedWidth, setComputedWidth] = useState(initialWidth);

  useEffect(() => {
    if (!width) {
      setComputedWidth(`${Math.floor(Math.random() * (maxWidth - minWidth) + minWidth)}%`);
    }
  }, [width, maxWidth, minWidth]);

  return (
    <span
      className={`loading-skeleton ${mode} ${className}`}
      style={{ width: computedWidth, height }}
    />
  );
};

export default Skeleton;

// Add the CSS styles directly in the JSX file
const style = document.createElement('style');
style.textContent = `
  .loading-skeleton {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border-radius: 4px;
    background-color: #DDDDDD;
  }

  .loading-skeleton.light {
    background-color: #DDDDDD;
  }

  .loading-skeleton.dark {
    background-color: #CDCDCD;
  }

  .loading-skeleton::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    height: 150%;
    animation: shimmer 3s ease-in-out infinite;
    transform: translateX(-110%);
  }

  .loading-skeleton.light::after {
    background-image: linear-gradient(85deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.3) 20%, rgba(255, 255, 255, 0.6) 60%, rgba(255, 255, 255, 0));
  }

  .loading-skeleton.dark::after {
    background-image: linear-gradient(85deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  }

  @keyframes shimmer {
    100% {
      transform: translateX(110%);
    }
  }
`;
document.head.appendChild(style);